/* eslint-disable jsx-a11y/anchor-has-content */
import { ButtonPrimary } from "@components/Reusables";
import {
  navArrowLeft,
  navArrowLeftActive,
  navArrowRight,
  navArrowRightActive,
  SIMULATOR_DAP_ILUSTRATION,
} from "@images";
import { useMediaQuery } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useStyles } from "./step1.style";
type Props = {
  onClick: () => void;
};

const Step1SavingAndInvestmentM3 = ({ onClick }: Props) => {
  const classes = useStyles();
  const slider = React.useRef(null);

  const [currentLeftArrow, setCurrentLeftArrow] = useState(navArrowLeft);
  const [currentRightArrow, setCurrentRightArrow] =
    useState(navArrowRightActive);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (currentSlide == 0) {
      setCurrentLeftArrow(navArrowLeft);
      setCurrentRightArrow(navArrowRightActive);
    }
    if (currentSlide == 1) {
      setCurrentLeftArrow(navArrowLeftActive);
      setCurrentRightArrow(navArrowRightActive);
    }
    if (currentSlide == 2) {
      setCurrentLeftArrow(navArrowLeftActive);
      setCurrentRightArrow(navArrowRight);
    }
  }, [currentSlide]);

  const arrowRight = {
    width: 50,
    height: 50,
    background: `url(${currentRightArrow})`,
  };
  const ellipseDots = {
    width: "14.9px",
    height: "14.9px",
    flexGrow: 0,
    margin: "",
    "background-image": "linear-gradient(335deg, #8352FD 127%, #8250ff 8%)",
  };
  const arrowLeft = {
    width: 50,
    height: 50,
    background: `url(${currentLeftArrow})`,
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    dotsClass: `slick-dots ${classes.dots}`,
  };
  const isMobile = useMediaQuery("(max-width: 1239px)");

  return (
    <>
      <div className={classes.container}>
        <div>
          <div className={classes.description}>
            Un{" "}
            <strong>
              depósito a plazo (DAP) es plata que se le entrega a una
              institución financiera con el objetivo de que la haga rentar o
              crecer a una tasa de interés previamente estipulada.
              <span className={classes.question}>
                ¿Qué debo saber sobre los Depósitos a Plazo?
              </span>
            </strong>
          </div>
          {!isMobile ? (
            <div className={classes.riel}>
              <div>
                <button
                  onClick={() => slider?.current?.slickPrev()}
                  style={arrowLeft}
                />
              </div>
              <div className={classes.msgWelcome}>
                <Slider ref={slider} {...settings}>
                  <div>
                    <div className={classes.cardDesktop}>
                      <span className={classes.number}>1</span>
                      <span className={classes.text}>{dataCards[0].text}</span>
                    </div>
                    <div className={classes.cardDesktop}>
                      <span className={classes.number}>2</span>
                      <span className={classes.text}>{dataCards[1].text}</span>
                    </div>
                  </div>
                  <div>
                    <div className={classes.cardDesktop}>
                      <span className={classes.number}>3</span>
                      <span className={classes.text}>{dataCards[2].text}</span>
                    </div>
                    <div className={classes.cardDesktop}>
                      <span className={classes.number}>4</span>
                      <span className={classes.text}>{dataCards[3].text}</span>
                    </div>
                  </div>
                  <div>
                    <div className={classes.cardDesktop}>
                      <span className={classes.number}>5</span>
                      <span className={classes.text}>{dataCards[4].text}</span>
                    </div>
                    <div className={classes.cardDesktop}>
                      <span className={classes.number}>6</span>
                      <span className={classes.text}>{dataCards[5].text}</span>
                    </div>
                  </div>
                </Slider>
              </div>
              <div>
                <button
                  onClick={() => slider?.current?.slickNext()}
                  style={arrowRight}
                />
              </div>
            </div>
          ) : (
            <section className={classes.cards}>
              {dataCards.map((card, i) => (
                <section
                  id={`circle${i}`}
                  className={classes.containerCard}
                  key={card.id}
                >
                  <div className={classes.cardDesktop}>
                    <span className={classes.number}>{i + 1}</span>
                    <span>{card.text}</span>
                  </div>
                </section>
              ))}
            </section>
          )}
        </div>{" "}
        {/* {isMobile && (
          <div className={classes.containerCircle}>
            <a href="#circle0" className={`${classes.circle}`} />
            <a href="#circle1" className={`${classes.circle}`} />
            <a href="#circle2" className={`${classes.circle}`} />
            <a href="#circle3" className={`${classes.circle}`} />
            <a href="#circle4" className={`${classes.circle}`} />
            <a href="#circle5" className={`${classes.circle}`} />
          </div>
        )} */}
        <div className="img">
          <img
            className={classes.imgSimulator}
            src={SIMULATOR_DAP_ILUSTRATION}
          />
        </div>
      </div>
      <div className={classes.buttonPrimary}>
        <ButtonPrimary
          secondaryVersion
          noDegraded
          onClick={onClick}
          text="Siguiente"
        />
      </div>
    </>
  );
};

export default Step1SavingAndInvestmentM3;

const dataCards = [
  {
    id: 1,
    text: (
      <span>
        Están pensados para personas <strong>poco tolerantes al riesgo,</strong>{" "}
        que buscan que su dinero no pierda valor en el tiempo.
      </span>
    ),
  },
  {
    id: 2,
    text: (
      <span>
        <strong>
          Sus plazos son fijos y no puedes retirar tu dinero antes de que estos
          se cumplan.
        </strong>{" "}
        Existen DAP de 7 días hasta 365 días.
      </span>
    ),
  },
  {
    id: 3,
    text: (
      <span>
        Existen de <strong>plazo fijo</strong> (una vez cumplido el plazo, el
        dinero se libera) o <strong>renovable</strong> (una vez cumplido el
        plazo tienes unos días para retirar el dinero y si no se retira, se
        renueva automáticamente a la tasa de interés vigente en el momento de la
        renovación).
      </span>
    ),
  },
  {
    id: 4,
    text: (
      <span>
        Puedes contratarlos en{" "}
        <strong>pesos, dólares, UF y hasta euros,</strong> dependiendo de la
        institución financiera
      </span>
    ),
  },
  {
    id: 5,
    text: (
      <span>
        Están sujetos a <strong>exención tributaria de 20 UTM:</strong> si tu
        ganancia anual es menor a 20 UTM (aproximadamente $1.200.000 pesos), no
        pagas impuestos por esta inversión.
      </span>
    ),
  },
  {
    id: 6,
    text: (
      <span>
        <strong>Cuentan con garantías estatales.</strong> Esto significa que si
        el banco no puede devolver el dinero que se ha depositado, entonces el
        Estado lo hará en su lugar.
      </span>
    ),
  },
];
