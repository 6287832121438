import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    display: "flex",
    "& >div": {
      width: "44rem",
      margin: "0 auto",
    },
    "& .img": {
      display: "flex",
      justifyContent: "start",
    },
    gap: "0rem",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      "& >div": {
        width: "100%",
        margin: "0 auto",
      },
      "& .img": {
        display: "flex",
        justifyContent: "center",
      },
    },
  },
  question: {
    marginTop: "2rem",
    display: "block",
  },
  description: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(1240)]: {
      maxWidth: "27rem",
      fontSize: "0.75rem",
      margin: " 0 auto",
    },
    "& >span": {
      display: "block",
      marginTop: "4rem",
    },
  },
  riel: {
    marginLeft: "-4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: "4rem",
    zIndex: 2,
    width: "44rem",
    "& >div:first-child": {
      width: 50,
    },
    "& >div:last-child": {
      width: 50,
    },
    padding: 30,
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",

    [theme.breakpoints.down(1240)]: {
      marginTop: "4rem",
      overflowX: "scroll",
      marginLeft: 0,
    },
    [theme.breakpoints.down(1100)]: {
      marginTop: "0rem",
    },
  },
  msgWelcome: {
    fontFamily: theme.typography.fontFamily,
    color: "#393e44",
    fontSize: "2rem",
    fontWeight: "normal",
    display: "block",
    width: "37rem",
    height: "auto",
    [theme.breakpoints.down(1240)]: {
      width: 300,
      margin: "0 0.5rem",
    },
  },
  dots: {
    bottom: "-1rem",
    "& li.slick-active button::before": {
      color: "#8352FD",
    },
    "& li": {
      "& button::before": {
        fontSize: theme.typography.pxToRem(14),
        color: "#9D9D9D",
        opacity: 0.5,
      },
    },
  },
  number: {
    padding: "3px 15px 3px 15px",
    height: "30px",
    width: "30px",
    fontSize: "1rem",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#4619b6",
    marginRight: "1rem",
    color: "white",
    [theme.breakpoints.down(1240)]: {
      margin: "2rem auto",
    },
  },
  cardDesktop: {
    display: "flex",
    alignItems: "center",
    padding: "20px 12px",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    margin: "1.5rem",
    borderRadius: 20,
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      width: "15rem",
      height: "20rem",
      fontSize: "0.75rem",
      textAlign: "center",
      margin: "2rem 0.7rem",
    },
  },
  text: {
    display: "block",
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
  },
  imgSimulator: {
    [theme.breakpoints.down(1460)]: {
      width: "30rem",
    },
    [theme.breakpoints.down(1400)]: {
      width: "25rem",
    },
    [theme.breakpoints.down(1330)]: {
      width: "20rem",
    },
    [theme.breakpoints.down(1200)]: {
      width: "20rem",
      margin: "0",
    },
  },
  buttonPrimary: {
    width: "70%",
    display: "flex",
    justifyContent: "end",
    margin: "4rem 5rem",
    [theme.breakpoints.down(1240)]: {
      width: "20rem",
      display: "flex",
      justifyContent: "center",
      margin: "4rem auto",
    },
    [theme.breakpoints.down(1330)]: {
      width: "60%",
    },
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
    width: "95%",
    margin: "0 auto",
    justifyContent: "space-between",
    fontFamily: "Montserrat",
    zIndex: 3,
    [theme.breakpoints.down(1280)]: {
      height: 400,
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      gap: "0rem",
      overflowX: "auto",
    },
  },
  containerCircle: {
    display: "flex",
    gap: "0.8rem",
    marginTop: "1rem",
    justifyContent: "center",
  },
  circle: {
    background: "#4619b6",
    width: "1rem",
    height: "1rem",
    borderRadius: "100%",
  },
}));
