import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Montserrat",
    color: theme.palette.text.secondary,
    display: "flex",
    gap: "2rem",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
    },
  },
  box: {
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    padding: "2rem 2.2rem",
    borderRadius: 30,
    width: "30rem",
    height: "31rem",
    [theme.breakpoints.down(1240)]: {
      width: "21rem",
      height: "33rem",
      margin: "0 auto",
      padding: "1.5rem 1.8rem",
    },
  },
  img: {
    width: "4rem",
    margin: "1rem auto 2rem",
    [theme.breakpoints.down(1240)]: {
      margin: "0.5rem auto 1rem",
    },
  },
  title: {
    display: "block",
    fontWeight: "bold",
    fontSize: "1.5rem",
    width: "14rem",
    margin: "0 auto",
    color: theme.palette.text.primary,
    marginBottom: "3rem",
    [theme.breakpoints.down(1240)]: {
      marginBottom: "2rem",
      width: "13rem",
      textAlign: "center",
    },
  },

  detail: {
    fontWeight: 300,
    "& strong": {
      fontWeight: "bold",
    },
  },
  imgSimulator: {
    [theme.breakpoints.down(1240)]: {
      width: "20rem",
      margin: "0 auto",
    },
  },
  buttonPrimary: {
    width: "90%",
    display: "flex",
    justifyContent: "end",
    margin: "4rem 5rem",
    [theme.breakpoints.down(1240)]: {
      width: "20rem",
      display: "flex",
      justifyContent: "center",
      margin: "4rem auto",
    },
  },
}));
