import React, { useContext, useState } from "react";
import { navigate, PageProps } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import {
  LayoutMissionDesktop,
  LayoutMissionMobile,
  SEO,
} from "@components/Structural";
import { completeMissionMutation, getUserMissions } from "@apollo";
import { MissionsInfo } from "@data";
import { useApolloClient } from "@apollo/client";
import { AppContext } from "@context";
import { nextMissionModalInfo } from "@utils";
import { Route } from "@interfaces";
import Step1SavingAndInvestmentM3 from "@components/Missions/SavingAndInvestment/M3B/step1";
import Step2SavingAndInvestmentM3 from "@components/Missions/SavingAndInvestment/M3B/step2";
import { useAuth } from "@hooks";

const savingInvestmentMB3: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });

  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const [step, isStep] = useState(0);
  const { userMissions } = getUserMissions();
  const client = useApolloClient();
  const [confettiFire, setConfettiFire] = useState<boolean>(false);
  const { openCongratsModal } = useContext(AppContext);

  const categoryMissions = userMissions.filter(
    (obj) =>
      obj.mission.category === MissionsInfo.SavingsInvestmentCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id === MissionsInfo.SavingsInvestmentCategory.m3_2.id
  )[0];

  const handleClick = () => {
    if (currentMission?.state === "PENDING") {
      try {
        completeMissionMutation(client, currentMission.mission.id);
        setConfettiFire(true);
        openCongratsModal(
          nextMissionModalInfo(currentMission, categoryMissions)
        );
      } catch (error) {
        // console.log(error);
      }
    } else {
      setConfettiFire(true);
      openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    }
  };
  if (step === 0) {
    return (
      <>
        <SEO />
        {!isMobile ? (
          <LayoutMissionDesktop
            title={"Depósitos a plazo"}
            number={3}
            confettiFire={confettiFire}
            onHouseClick={() => navigate(Route.savingsAndInvestment)}
          >
            <Step1SavingAndInvestmentM3 onClick={() => isStep(1)} />
          </LayoutMissionDesktop>
        ) : (
          <LayoutMissionMobile
            title={"Depósitos a plazo"}
            number={3}
            confettiFire={confettiFire}
            onHouseClick={() => navigate(Route.savingsAndInvestment)}
          >
            <Step1SavingAndInvestmentM3 onClick={() => isStep(1)} />
          </LayoutMissionMobile>
        )}
      </>
    );
  } else {
    return (
      <>
        <SEO />
        {!isMobile ? (
          <LayoutMissionDesktop
            title={"Depósitos a plazo"}
            number={3}
            confettiFire={confettiFire}
            onHouseClick={() => navigate(Route.savingsAndInvestment)}
            secondLayoutForShortcuts
            setPage={isStep}
            pageNumber={0}
          >
            <Step2SavingAndInvestmentM3 onClick={handleClick} />
          </LayoutMissionDesktop>
        ) : (
          <LayoutMissionMobile
            title={"Depósitos a plazo"}
            number={3}
            confettiFire={confettiFire}
            onHouseClick={() => navigate(Route.savingsAndInvestment)}
            secondLayoutForShortcuts
            setPage={isStep}
            pageNumber={0}
          >
            <Step2SavingAndInvestmentM3 onClick={handleClick} />
          </LayoutMissionMobile>
        )}
      </>
    );
  }
};

export default savingInvestmentMB3;

const useStyles = makeStyles((theme) => ({}));
