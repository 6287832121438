import { ButtonPrimary } from "@components/Reusables";
import { EYE_ICON, SIMULATOR_DAP_ILUSTRATION } from "@images";
import React, { Dispatch, SetStateAction } from "react";
import { useStyles } from "./step1.style";
type Props = {
  onClick: () => void;
};

const Step2SavingAndInvestmentM3 = ({ onClick }: Props) => {
  const classes = useStyles();

  return (
    <section>
      <div className={classes.container}>
        <div className={classes.box}>
          <img className={classes.img} src={EYE_ICON} />
          <span className={classes.title}>¿En qué me fijo?</span>
          <span className={classes.detail}>
            <strong>
              El mejor depósito será el que te ofrezca el mayor interés.
            </strong>{" "}
            La forma más fácil de <strong>comparar</strong> los depósitos es ver
            la <strong>tasa base</strong> a 30 días que entrega una institución,
            por ejemplo, si buscamos los depósitos a plazo para 30 días, tenemos
            que ver qué institución ofrece la tasa base a 30 días más alta, ya
            que esa es la que va a entregar el{" "}
            <strong>mayor monto al final del período.</strong>
          </span>
        </div>
        <div>
          <img
            className={classes.imgSimulator}
            src={SIMULATOR_DAP_ILUSTRATION}
          />
        </div>
      </div>
      <div className={classes.buttonPrimary}>
        <ButtonPrimary
          secondaryVersion
          noDegraded
          onClick={onClick}
          text="Terminar misión"
        />
      </div>
    </section>
  );
};

export default Step2SavingAndInvestmentM3;
